export const environment = {
  version: '0.0.0',
  production: false,
  title: 'IOT DEV',
  apiUrl: 'https://freeze.api.groupe-seb.com',
  apiKey: 'puVdQHQNS3rjx2e8RDWMhkTmyGkXLCdC',
  backendUrl: 'https://freeze.iot.api.groupe-seb.com/api/v1',
  metaVersionsTable: 'Meta-version',
  metaVersionsIndex: 'activated-thingType-index',
  firmwaresTable: 'Firmwares',
  firmwaresIndex: 'activated-thingType-index',
  iotDevicesTable: 'IOTDevices',
  iotDevicesIndex: 'macAddress-index',
  iotDevicesStatsTable: 'IOTDevicesStats',
  firmwareBrandAreaTable: 'FirmwareBrandArea',
  metaversionJobsTable: 'Meta-version-job',
  metaversionJobsIndex: 'metaversionId-index',
  metaversionJobsGroupIdIndex: 'groupId-jobId-index',
  groupOfThingsTable: 'GroupOfThings',
  groupOfThingsthingTypeIndex: 'thingType-groupId-index',
  firmwaresBucket: 'iot-dev-com-groupeseb-firmwares',
  temporaryFirmwaresBucket: 'iot-dev-com-groupeseb-temporary-firmwares',
  userLoginHistoryTable: 'ConsoleUserLoginHistory',
  userHistoryTTL: 4320, // hours
  cognitoIdpName: 'adfs',
  cognitoUserPoolId: 'eu-west-1_PHFQUmFXD',
  cognitoClientId: '7gfnatrjmpo4uva0alojlau5o1',
  cognitoIdentityPoolId: 'eu-west-1:78718da0-2ec6-4866-a28d-e746161d2490',
  cognitoDomain: 'freeze-iot-api-groupe-seb.auth.eu-west-1.amazoncognito.com',
  cognitoSignInUrl: 'https://iot.console.dev.groupe-seb.com/loading',
  cognitoSignOutUrl: 'https://iot.console.dev.groupe-seb.com',
  webDomainNameV2: 'iot.console.dev.groupe-seb.com',
  cognitoReponseType: 'code',
  awsRegion: 'eu-west-1',
  iotEndpoint: 'a7wp1l16ckfad-ats.iot.eu-west-1.amazonaws.com',
  iotPolicyName: 'AdminConsole-IotPolicy-1344DULO9YO6E',
  unsignedFolder: 'dev/unsigned',
  signedFolder: 'dev/signed',
  domainCertificateARN: 'arn:aws:acm:us-east-1:968232977069:certificate/ac2e8141-9624-44c0-8c73-6fc879d7443f',
  searchMaxResults: 20,
  thingArnPrefix: 'arn:aws:iot:eu-west-1:968232977069:thing/',
  crossOriginLambda: 'CallCrossOrigin',
  teamsWebhookUrl: 'https://groupeseb.webhook.office.com/webhookb2/79f88e84-15e1-4ea1-8666-5ebef28ac4ba@bcccbda4-a4dc-4cf5-b7cb-7a3869ac0856/IncomingWebhook/65e534c4cd7f4788a4350474d4f3fa5d/50fd6373-8fbd-4619-9d4d-454b55b3a12a',
  teamsMessageColor: 'FFFF00',
  envTag: 'development',
  s3ProxyReadLambda: 'S3ProxyRead',
  s3ProxyWriteLambda: 'S3ProxyWrite',
};
