import { ThingDocument } from '@aws-sdk/client-iot';
import * as moment from 'moment';
import { SearchThing } from '../api/backend/services/things/things.model';
import { Shadow } from './shadow';
import { ThingAttributes } from './thingtype';

export class ThingListDisplay {
  constructor(
    public name: string,
    public nickname?: string,
    public groups?: string,
    public connectionTime?: Date | null,
    public connectionTimeStr?: string | null,
    public currentFirmware?: string | null,
    public firmwareDetails?: { wifi?: string; ui?: string },
    public nextFirmwareReported?: string,
    public nextFirmwareDesired?: string,
    public attributes?: ThingAttributes,
  ) {}

  static createThingListDisplay(
    name: string,
    shadow: Shadow,
    attributes: ThingAttributes | Record<string, string> | undefined,
  ): ThingListDisplay {
    let lastConnection = null;
    let connectionTime = null;
    let connectionTimeStr = null;

    if (shadow.lastConnection()) {
      lastConnection = moment(shadow.lastConnection());
      connectionTime = lastConnection.toDate();
      connectionTimeStr = lastConnection.format('YYYY-MM-DD HH:mm:ss');
    }

    const firmwareDetails = {
      wifi: shadow.reportedFirmware()?.files?.find((_) => _.type === 'wifi')
        ?.version,
      ui: shadow.reportedFirmware()?.files?.find((_) => _.type === 'ui')
        ?.version,
    };
    const currentFirmware =
      (firmwareDetails?.wifi ? `WIFI ${firmwareDetails.wifi}` : '') +
      (firmwareDetails.wifi && firmwareDetails.ui ? ' - ' : '') +
      (firmwareDetails?.ui ? `UI ${firmwareDetails.ui}` : '');
    const nextFirmwareReported = shadow.reportedNextFirmware()?.version;
    const nextFirmwareDesired = shadow.desiredNextFirmware()?.version;

    const groups = attributes?.customGroups;
    const nickname = attributes?.nickname;

    return new ThingListDisplay(
      name,
      nickname,
      groups,
      connectionTime,
      connectionTimeStr,
      currentFirmware,
      firmwareDetails,
      nextFirmwareReported,
      nextFirmwareDesired,
      ThingAttributes.fromAttributes(attributes),
    );
  }

  static fromThingDocument(thingDocument: ThingDocument): ThingListDisplay {
    const name = thingDocument.thingName ?? '';
    const shadow = new Shadow(JSON.parse(thingDocument.shadow ?? '{}'));
    const attributes = thingDocument.attributes;

    return ThingListDisplay.createThingListDisplay(name, shadow, attributes);
  }

  static fromSearchThings(searchThings?: SearchThing[]): ThingListDisplay[] {
    return searchThings?.map(this.fromSearchThing) ?? [];
  }

  static fromSearchThing(searchThing: SearchThing): ThingListDisplay {
    const name = searchThing.thingName ?? '';
    const shadow = Shadow.fromShadowObject(searchThing.shadow ?? {});
    const attributes = searchThing.attributes;

    return ThingListDisplay.createThingListDisplay(name, shadow, attributes);
  }
}
