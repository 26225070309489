<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Statistics</h1>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <div class="card-body d-flex flex-row justify-content-between">
      <form action="" class="form-inline">
        <div class="form-row align-items-center">
          <div class="col-auto ml-2">
            <select
              class="form-select"
              id="thingType"
              name="thingType"
              [disabled]="dailyNewDevicesLoading"
              [formControl]="thingTypeFilter"
            >
              <option *ngFor="let filter of filters">{{ filter.name }}</option>
            </select>
          </div>
          <div class="col-auto ml-2">
            <button class="btn btn-primary" (click)="resetFilter()">
              Reset filters
            </button>
          </div>
        </div>
      </form>
      <div ngbDropdown class="dropdown">
        <button ngbDropdownToggle class="btn btn-primary dropdown-toggle" type="button" [disabled]="!thingType || !exportableThingTypes.includes(thingType)">
          Export shadows
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
          @for (exportDate of exportDates; track exportDate) {
            <a class="dropdown-item" (click)="downloadExportFile(exportDate)">{{exportDate}}</a>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary">Daily New Devices</h6>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="!this.dailyNewDevicesLoading; else elseBlock">
        <div class="col" style="position: relative">
          <div
            class="Bullet-sc-ugsus7 haJNPv"
            (click)="onClick(7)"
            [ngClass]="{ active: numberOfDays === 7 }"
          >
            Last 7 Days
          </div>
          <div
            class="Bullet-sc-ugsus7 haJNPv"
            (click)="onClick(14)"
            [ngClass]="{ active: numberOfDays === 14 }"
          >
            Last 14 Days
          </div>
          <div
            class="Bullet-sc-ugsus7 haJNPv"
            (click)="onClick(30)"
            [ngClass]="{ active: numberOfDays === 30 }"
          >
            Last 30 Days
          </div>
          <div
            class="Bullet-sc-ugsus7 haJNPv"
            (click)="onClick(60)"
            [ngClass]="{ active: numberOfDays === 60 }"
          >
            Last 60 Days
          </div>
          <div *ngIf="dailyNewDevicesData">
            <canvas
              baseChart
              height="60"
              [datasets]="barChartData"
              [labels]="dailyNewDevicesData.labels"
              [options]="barChartOptions"
              [legend]="false"
              type="bar"
              class="chartjs-render-monitor"
            >
            </canvas>
          </div>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="alert alert-primary" role="alert">Loading...</div>
      </ng-template>
    </div>
  </div>
</div>
