import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceException } from '@smithy/smithy-client';
import { CreateJobCommandOutput } from '@aws-sdk/client-iot';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { DatabaseService } from '../api/database.service';
import { DeployService } from '../api/deploy.service';
import {
  DeployCount,
  DeployCountDetail,
  RegistryService,
} from '../api/registry.service';
import { StoreService } from '../lib/store.service';
import { DeployAttribute } from '../models/deploy-attributes';
import {
  CriteriaKey,
  CriteriaType,
  ParsedCriteriaKey,
} from '../models/firmware';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { MetaVersion } from '../models/metaversion/meta-version';
import { ThingGroup } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { FeatureGroupEnum } from '../shared/user-rights-management/feature-group.enum';
import { VersionFlag } from '../models/backend/firmware/version-flag.enum';

@Component({
  selector: 'app-metaversion',
  templateUrl: './metaversion.component.html',
  styleUrls: ['./metaversion.component.css'],
})
export class MetaversionComponent implements OnInit, OnDestroy {
  protected readonly VersionFlag = VersionFlag;

  @ViewChild('dialog') dialog?: ElementRef;
  @ViewChild('dialogNoThingGroup') dialogNoThingGroup?: ElementRef;

  isLoading = false;
  deployAttribute = 'thingType' as DeployAttribute;
  metaVersion?: MetaVersion;
  metaVersionId?: string;
  deployCount?: DeployCount;
  metaVersionFile = '';

  massDeployRole = false;

  shouldDisplayRange = false;
  shouldDisplayCmmf = false;
  shouldDisplayIndice = false;
  shouldDisplayBrandArea = false;

  jobs$?: Observable<MetaVersionJob[]>;
  multiOnly$ = new BehaviorSubject<boolean>(false);
  formGroupJobsFilters = new UntypedFormGroup({
    multiOnly: new UntypedFormControl(false),
  });

  resetTableFilters = new EventEmitter<void>();

  preparing = false;
  deploying = false;
  destroy$ = new Subject<void>();
  loadThingGroups$ = new BehaviorSubject<boolean>(false);
  thingGroups$?: Observable<ThingGroup[]>;
  thingGroupsExist$?: Observable<boolean>;

  readonly AuditType = AuditType;

  constructor(
    private readonly router: Router,
    private readonly notif: NotificationService,
    private readonly store: StoreService,
    private readonly dataBaseService: DatabaseService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly registryService: RegistryService,
    private readonly deployService: DeployService,
    private readonly auditService: AuditService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.massDeployRole = this.store.userHasGroup(
      FeatureGroupEnum.DEPLOY_WITH_CRITERIA,
    );

    this.activatedRoute.paramMap.subscribe(async (params) => {
      if (params.get('metaversionId')) {
        this.isLoading = true;
        this.metaVersionId = params.get('metaversionId') as string;

        this.jobs$ = combineLatest([
          this.dataBaseService.getJobsForMetaVersion(this.metaVersionId),
          this.multiOnly$,
        ]).pipe(
          map(([jobs, multiOnly]) =>
            jobs.filter((job) => !multiOnly || job.jobType === 'MULTI'),
          ),
        );

        this.metaVersion = (
          await this.dataBaseService.listMetaVersions(1)
        ).find((_) => _.id === this.metaVersionId);

        if (this.metaVersion !== undefined && this.metaVersion !== null) {
          this.metaVersionFile = this.metaVersion.displayFirmwares();
        }

        const criteriaType =
          this?.metaVersion?.uiFirmware?.criteriaType || CriteriaType.THINGTYPE;
        this.shouldDisplayRange = [
          CriteriaType.RANGE,
          CriteriaType.RANGE_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayCmmf = [
          CriteriaType.CMMF,
          CriteriaType.CMMF_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayIndice = [
          CriteriaType.RANGE_INDICE,
          CriteriaType.CMMF_INDICE,
        ].includes(criteriaType);
        this.shouldDisplayBrandArea = Object.keys(
          this.metaVersion?.uiFirmware?.s3Key || {},
        ).some(
          (s3key) =>
            ParsedCriteriaKey.fromCriteriaKey(s3key as CriteriaKey)
              .brandArea !== 'NA',
        );

        if (this.massDeployRole) {
          this.initThingGroupObservables();
        }

        this.isLoading = false;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params?.jobsMultiOnly === 'true') {
        this.multiOnly$.next(true);
        this.formGroupJobsFilters.patchValue({ multiOnly: true });
      }
    });
  }

  public prepareDeployment(): void {
    if (!this.massDeployRole) {
      return;
    }
    this.preparing = true;
    this.loadThingGroups$.next(true);
  }

  public startDeployment(): void {
    if (!this.massDeployRole || !this.metaVersion?.id) {
      return;
    }
    this.deploying = true;

    this.thingGroups$
      ?.pipe(
        switchMap((thingGroups) =>
          this.registryService
            .countDeployGroups(thingGroups, this.metaVersion?.id ?? '')
            .pipe(
              tap((countDetails) => this.initThingCount(countDetails)),
              mapTo(thingGroups),
            ),
        ),
      )
      .subscribe((thingGroups) =>
        this.askForConfirmationAndDeploy(thingGroups),
      );
  }

  /**
   * Shows the user an error modal to warn them the Thing Groups don't exist
   */
  showBlockingModalNoThingGroupExist(): void {
    this.modalService.open(this.dialogNoThingGroup, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });
  }

  filterJobs(): void {
    this.multiOnly$.next(this.formGroupJobsFilters.value.multiOnly ?? false);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        jobsMultiOnly: this.formGroupJobsFilters.value.multiOnly ? true : null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  resetFilters(): void {
    this.resetTableFilters.emit();

    if (this.formGroupJobsFilters.value.multiOnly) {
      this.formGroupJobsFilters.reset();
      this.filterJobs();
    }
  }

  private initThingCount(countDetails: DeployCountDetail[]): void {
    const total = countDetails.reduce(
      (curTotal: number, countDetail: DeployCountDetail) =>
        curTotal + countDetail.count,
      0,
    );

    this.deployCount = {
      totalCount: total,
      details: countDetails,
    };
  }

  private initThingGroupObservables(): void {
    this.thingGroups$ = this.loadThingGroups$.pipe(
      switchMap(() =>
        this.dataBaseService
          .getFirmwares(this.metaVersion?.getFirmwareIds() ?? [])
          .pipe(
            tap((firmwares) => {
              this.metaVersion?.setFirmwares(firmwares);

              // Verify metaversion signature
              if (!this.metaVersion?.isSigned()) {
                throw new Error('A firmware file is not signed yet!');
              }
            }),
            switchMap(() => {
              if (this.metaVersion) {
                return this.deployService.prepareJob(this.metaVersion);
              }
              return of([]);
            }),
            takeUntil(this.destroy$),
            catchError((err) => this.handleThingGroupsError(err)),
          ),
      ),
      catchError((err) => this.handleThingGroupsError(err)),
      tap(() => (this.preparing = false)),
      shareReplay(1),
    );

    this.thingGroupsExist$ = this.thingGroups$.pipe(
      map((thingGroups) => {
        if (thingGroups.length === 0) {
          return false;
        }
        return thingGroups.every((_tg) => _tg.status === null || 'ACTIVE');
      }),
      shareReplay(1),
    );
  }

  private handleThingGroupsError(
    err: ServiceException,
  ): Observable<ThingGroup[]> {
    console.error(err);
    if (err?.$response?.statusCode === 404) {
      this.showBlockingModalNoThingGroupExist();
    } else {
      if (this.preparing) {
        this.notif.showError(err.message, err);
      } else {
        this.notif.showInfo(err.message);
      }
    }

    return of([]);
  }

  private askForConfirmationAndDeploy(thingGroups: ThingGroup[]): void {
    // Ask for user confirmation to approve the deploy
    this.modalService
      .open(this.dialog, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
      })
      .result.then((result) => {
        if (result && this.metaVersion) {
          // Upgrade devices by starting the job in case of confirmation
          this.deployService
            .startJob(thingGroups, this.metaVersion)
            .pipe(
              switchMap(
                (
                  res: CreateJobCommandOutput,
                ): Observable<CreateJobCommandOutput> => {
                  if (!res?.jobId || !this.metaVersion?.id) {
                    throw new Error('Missing input data');
                  }
                  return this.dataBaseService
                    .insertJobForMetaversion({
                      metaversionId: this.metaVersion?.id,
                      jobId: res.jobId,
                      date: new Date(),
                      jobType: 'MULTI',
                    })
                    .pipe(
                      catchError((err) => {
                        this.notif.showError(
                          err?.message ?? 'Could not insert job in DB',
                          err,
                        );
                        return of(void 0);
                      }),
                      mapTo(res),
                    );
                },
              ),
            )
            .subscribe({
              next: (res: CreateJobCommandOutput) => {
                this.deploying = false;

                this.auditService.pushEvent({
                  type: AuditType.DEPLOYMENT,
                  action: AuditAction.START,
                  resourceId: res.jobId,
                  additionalData: {
                    metaversion_id: this.metaVersionId,
                    thing_type: this.metaVersion?.thingType,
                    target: 'MULTI',
                  },
                });

                this.notif.showSuccess(
                  `Deployment in progress, Job id is ${res?.jobId}`,
                );
                this.router.navigateByUrl('/deployments');
              },
              error: (err) => {
                this.notif.showError(err?.message ?? 'An error occurred', err);
                this.deploying = false;
              },
            });
        } else {
          this.deploying = false;
        }
      });
  }
}
