import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendError } from '../../../../models/backend/backend-error';

@Injectable()
export class ErrorLoggerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    handler: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return handler.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          throw new BackendError(
            err.error.message ?? err.statusText,
            err.error.details ??
            (typeof err.error === 'string' ? [err.error] : [err.message]),
            err,
          );
        }

        throw err;
      })
    );
  }
}
