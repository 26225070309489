import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StatisticsService } from '../api/backend/services/statistics/statistics.service';
import { RegistryService, StatisticsLine } from '../api/registry.service';
import { ThingTypesService } from '../api/thing-types.service';
import {
  exportableThingTypes,
  ExportStatistics,
} from '../models/backend/statistics/export-statistics';
import { ThingType } from '../models/thingtype';
import { ThingTypesSelect } from '../models/thingtypesselect';
import { NotificationService } from '../shared/notification.service'; /* eslint @typescript-eslint/no-explicit-any: 0 */

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
})
export class StatisticsComponent implements OnInit {
  filters: ThingTypesSelect[] = [{ id: '1', name: 'Thing Type' }];
  dailyNewDevicesData?: ChartData;
  barChartData: any[] = [];
  thingTypeFilter = new UntypedFormControl(this.filters[0].name);
  thingType = '';
  dailyNewDevicesLoading = false;
  numberOfDays = 7;
  barChartOptions: ChartConfiguration['options'] = {
    backgroundColor: 'rgba(217, 26, 0)',
    borderColor: 'rgba(217, 26, 0)',
    responsive: true,
  };

  exportDates: string[] = [];

  constructor(
    private getThingTypesservice: ThingTypesService,
    private notif: NotificationService,
    private statisticsService: StatisticsService,
    private registryService: RegistryService,
  ) {}

  ngOnInit(): void {
    this.getThingTypes();
    this.search().catch((err: Error) => this.notif.showError(err.message, err));

    // generates the previous last 5 mondays date
    this.exportDates = Array(5)
      .fill(new Date())
      .map((date: Date, index) => {
        return moment(date)
          .weekday(1)
          .subtract(7 * index, 'days')
          .format('YYYY-MM-DD');
      });

    this.thingTypeFilter.valueChanges.subscribe((filterValue) => {
      if (filterValue === 'Thing Type') {
        this.thingType = '';
      } else {
        this.thingType = filterValue;
      }
      this.dailyNewDevicesLoading = true;
      this.search();
    });
  }

  resetFilter(): void {
    this.thingType = '';
    this.dailyNewDevicesLoading = true;
    this.thingTypeFilter.setValue(this.filters[0].name);
    this.search();
  }

  onClick(nbJ: number): void {
    this.dailyNewDevicesLoading = true;
    this.numberOfDays = nbJ;
    this.search();
  }

  async search(): Promise<void> {
    const dailyNewDevicesData =
      await this.registryService.getDailyNewDevicesStats(
        this.thingType as ThingType,
        this.numberOfDays,
      );

    this.dailyNewDevicesLoading = false;
    this.dailyNewDevicesData = this.buildData(
      dailyNewDevicesData,
      false,
      false,
      this.numberOfDays + 1,
      true,
    );

    this.barChartData = [{ data: this.dailyNewDevicesData.datasets?.[0].data }];
  }

  private buildData(
    stats: StatisticsLine[],
    randomColor: boolean,
    sortValues: boolean,
    maxItems: number,
    displayZeros: boolean,
  ): ChartData {
    const ordered = sortValues
      ? stats.sort((a, b) => (a.count > b.count ? -1 : 1))
      : stats;
    const displayed: StatisticsLine[] = [];
    let countOthers = 0;
    let hasOthers = false;
    for (const item of ordered) {
      if (displayZeros || item.count > 0) {
        if (displayed.length < maxItems - 1) {
          displayed.push(item);
        } else {
          hasOthers = true;
          countOthers += item.count;
        }
      }
    }
    if (hasOthers) {
      displayed.push({
        label: 'Others',
        count: countOthers,
      });
    }
    return {
      labels: displayed.map((_) => _.label),
      datasets: [
        {
          data: displayed.map((_) => _.count),
          backgroundColor: randomColor
            ? displayed.map((_) => this.getRandomColor())
            : '#d91a00',
        },
      ],
    };
  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private getThingTypes(): void {
    this.getThingTypesservice
      .getThingTypes()
      ?.pipe(
        catchError((err) => {
          this.notif.showError(err.message, err);
          return of([]);
        }),
      )
      .subscribe((thingTypes) => {
        let index = 2;
        for (const type of thingTypes) {
          this.filters.push({ id: index.toString(), name: type });
          index++;
        }
      });
  }

  downloadExportFile(date: string): void {
    this.statisticsService
      .getExportFileUrl(date, this.thingType)
      .pipe(
        catchError((err) => {
          console.error(err.message, err);
          this.notif.showError('File does not exist');
          return of(null);
        }),
      )
      .subscribe((value: ExportStatistics | null) => {
        if (value) {
          window.open(value.presignedUrl, '_blank');
        }
      });
  }

  protected readonly exportableThingTypes = exportableThingTypes;
}
