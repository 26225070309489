import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { ThingsService } from '../../../api/backend/services/things/things.service';
import {
  DownloadedLog,
  ProductLog,
} from '../../../models/backend/product-logs';
import { DeleteProductLogDialogComponent } from '../../../shared/delete-product-log-dialog/delete-product-log-dialog.component';
import { CustomFilters } from '../../../shared/ngx-table-with-query-params-persistence/ngx-table-with-query-params-persistence.component';
import { NotificationService } from '../../../shared/notification.service';
import { LogsReaderComponent } from '../logs-reader/logs-reader.component';

@Component({
  selector: 'app-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsListComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('deleteDialog') deleteDialog?: ElementRef;

  @Input()
  thingname?: string;
  @Input()
  mac?: string;
  @Input()
  autoRefresh = false;
  @Input()
  refresh$ = new Subject<void>();

  _logs$?: Observable<ProductLog[]>;

  _download$ = new Subject<ProductLog>();

  columns: Columns[] = [
    { key: 'displayDate', title: 'Log timestamp', orderBy: 'desc' },
    { key: 'bytes', title: 'File size', searchEnabled: false },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '10%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
  };

  localFiltersFormGroup = new FormGroup({
    creationDateMin: new FormControl<string>(''),
    creationDateMax: new FormControl<string>(''),
  });

  customMatchers: CustomFilters<ProductLog> = {
    creationDateMin: (controlValue: string | null, item: ProductLog) =>
      controlValue
        ? controlValue <= item.displayDate.substring(0, controlValue.length)
        : true,
    creationDateMax: (controlValue: string | null, item: ProductLog) =>
      controlValue
        ? controlValue >= item.displayDate.substring(0, controlValue.length)
        : true,
  };

  private saveAs = FileSaver.saveAs;

  constructor(
    private readonly thingService: ThingsService,
    private readonly notif: NotificationService,
    private readonly modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this._logs$ = merge(
      this.refresh$,
      timer(0, 5000).pipe(filter(() => this.autoRefresh)),
    ).pipe(
      switchMap(() => {
        if (!this.thingname || !this.mac) {
          return of([]);
        }

        return this.thingService
          .getProductLogsList(this.thingname, this.mac)
          .pipe(
            catchError((err) =>
              this.notif
                .showError('Unable to fetch product logs', err)
                .pipe(map(() => [])),
            ),
          );
      }),
      shareReplay(1),
    );

    this._download$
      .pipe(
        switchMap((logToDownload) => this.getDownloadObservable(logToDownload)),
      )
      .subscribe((result: { file: DownloadedLog; filename: string } | void) => {
        if (result) {
          this.saveAs(result.file.getSavableBlob(), result.filename);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.thingname && this.mac && (changes.thingname || changes.mac)) {
      this.refreshList();
    }
  }

  ngAfterViewInit(): void {
    this.refreshList();
  }

  refreshList(): void {
    this.refresh$.next();
  }

  typed(untyped: ProductLog): ProductLog {
    return untyped;
  }

  showReader(log: ProductLog): void {
    const readerComponent: LogsReaderComponent = this.modal.open(
      LogsReaderComponent,
      {
        size: 'xl',
      },
    ).componentInstance;

    readerComponent.log = log;
    readerComponent.logFile$ = this.getDownloadObservable(log);
    readerComponent.thingName = this.thingname;
    readerComponent.mac = this.mac;
    readerComponent.deleted.subscribe((deleted) => {
      if (deleted) {
        this.refreshList();
      }
    });
  }

  deleteLog(log: ProductLog): void {
    const dialogInstance = this.modal.open(DeleteProductLogDialogComponent, {
      backdrop: 'static',
      centered: true,
    });

    dialogInstance.componentInstance.thingName = this.thingname;
    dialogInstance.componentInstance.mac = this.mac;
    dialogInstance.componentInstance.logfile = log.filename;

    dialogInstance.result.then((deleted) => {
      if (deleted) {
        this.refreshList();
      }
    });
  }

  private getDownloadObservable(
    logToDownload: ProductLog,
  ): Observable<void | { file: DownloadedLog; filename: string }> {
    return this.thingService
      .downloadLogFile(
        logToDownload.originThingnameOrMac,
        logToDownload.filename,
      )
      .pipe(
        map((file: DownloadedLog) => ({
          file,
          filename: `LOG_${this.thingname}_${logToDownload.displayDate.replace(/:/g, '-').replace(' ', '_')}.txt`,
        })),
        catchError((err) => {
          console.log(err);
          return this.notif.showError(
            `Unable to download log file from ${logToDownload.displayDate}`,
            err,
          );
        }),
        shareReplay(1),
      );
  }
}
